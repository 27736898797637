import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import {FormControl, FormGroupDirective, Validators} from '@angular/forms';
import { Alert } from 'selenium-webdriver';


@Component({
    selector: 'validita-attestato',
    templateUrl: './validita-attestato.html',
    styleUrls: ['./validita-attestato.scss', './table.component.scss']
})
export class ValiditaAttestatoComponent implements OnInit {
    @ViewChild('contactForm') contactForm:NgForm;

    public showCorsista=0;
    public arrayAttestati: any=[];

    public detailUser: any=[];

    constructor(
        private crudService: CrudService,
    ) { }

    ngOnInit(): void {
        //mi recupero il dettaglio dell'user dopo il LOGIN
        this.detailUser=JSON.parse(localStorage.getItem('user_id'));
    }

    bgImage = [
        {
            img: 'assets/img/register-shape_1.jpg'
        }
    ]

    submit(form){
        var name = form.name;
        var cognome = form.cognome;
        var data_nascita = form.data_nascita;
        var cf = form.cf;

        if(name && cognome && data_nascita && cf) {
            let arrayDetails={'nome':name,
                        'cognome': cognome,
                        'data_nascita':data_nascita,
                        'cf': cf};

            this.crudService.apiVerificaAttestato(arrayDetails).subscribe(responseData => {
                console.log(responseData);
                if(Object.keys(responseData).length) {
                    this.showCorsista=1;
                    this.arrayAttestati=responseData;
                }
                else {
                   this.showCorsista=0;
                   this.showErrorProtocollo();
                }
                /*
                if(responseData[0]['protocollo']) {
                    console.log(responseData['protocollo']);
                    this.showProtocollo(responseData['protocollo'], responseData['dataAttestatoFormattato']);
                }
                else {
                    this.showErrorProtocollo();
                }
                */
            });
        }
        else {
            this.errorForm();
        }
    }

    showProtocollo(protocollo, giorno) {
        Swal.fire({
            title:'Attestato Valido!',
            text: 'numero di protocollo: '+protocollo+', con certificato rilasciato il giorno: '+giorno,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    showErrorProtocollo() {
        Swal.fire({
            title:'Non Trovato!',
            text: 'non è stato trovato nessun atteatato valido con i dati inseriti. Riprova o contatta il servizio clienti.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Riprova',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    errorForm() {
        Swal.fire({
            title:'Campi Obbligatori!',
            text: 'bisogna compilare tutti i campi richiesti',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    emailSuccess() {
        Swal.fire({
            title:'Richiesta Inviata!',
            text: 'la tua richiesta è stat inviata con successo, sarai contattato dal nostro team il prima possibile',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

}
